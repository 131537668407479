import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const ElementsPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="Contact" />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2 id="forms">Get in touch</h2>
          <h4>Lets discuss how we can work together</h4>
          <form name="contact" method="POST" data-netlify="true">
            <input type="hidden" name="form-name" value="contact" />
            <div className="row gtr-uniform">
              <div className="col-6 col-12-xsmall">
                <input type="text" name="name" id="name" placeholder="Name" />
              </div>
              <div className="col-6 col-12-xsmall">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                />
              </div>
              {/* Break */}
              <div className="col-6 col-12-xsmall">
                <select name="category" id="category">
                  <option value>- Service Type -</option>
                  <option value={1}>Portraits</option>
                  <option value={1}>Family</option>
                  <option value={1}>Events</option>
                  <option value={1}>Real Estate</option>
                  <option value={1}>Wedding</option>
                  <option value={1}>Performances</option>
                </select>
              </div>
              <div className="col-6 col-12-xsmall">
                <input
                  type="number"
                  name="budget"
                  id="budget"
                  placeholder="$ Budget"
                />
              </div>
              {/* Break */}
              {/* <div className="col-6 col-12-small">
            <input type="checkbox" id="demo-copy" name="demo-copy" />
            <label htmlFor="demo-copy">Email me a copy</label>
          </div>
          <div className="col-6 col-12-small">
            <input
              type="checkbox"
              id="demo-human"
              name="demo-human"
              defaultChecked
            />
            <label htmlFor="demo-human">I am a human</label>
          </div> */}
              {/* Break */}
              <div className="col-12">
                <textarea
                  name="message"
                  id="message"
                  placeholder="Briefly describe what you're looking for"
                  rows={6}
                  defaultValue={""}
                />
              </div>
              {/* Break */}
              <div className="col-12">
                <input
                  type="submit"
                  defaultValue="Send Message"
                  className="primary"
                />
              </div>
            </div>
          </form>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    smallPic: file(
      relativePath: { eq: "fabio-comparelli-696506-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    medPic: file(relativePath: { eq: "sophia-valkova-30139-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    largePic: file(
      relativePath: { eq: "vladimir-malyutin-98174-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ElementsPage location={props.location} data={data} {...props} />
    )}
  />
)
